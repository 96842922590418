import {useTranslations} from "next-intl";
import Image from "next/image";
import { HeaderButton } from "@/components/header/HeaderButton";
import MobileMegaMenuWrapper from "@/components/mega-menu/MobileMegaMenuWrapper";
import MobileLanguageMenuWrapper from "@/components/mega-menu/language/MobileLanguageMenuWrapper";

export default function GenericMobileHeader({ containerClassName, mode }: GenericHeaderProps) {
	const t = useTranslations('');

	return (
		<div className={`w-full fixed bg-navbar_bg items-center flex h-12 md:h-20 z-10 px-4 md:px-9 ${containerClassName}`}>
			<nav className={"flex justify-evenly w-full"}>
				<div className={"flex flex-1 items-center"}>
					<a href={"/"}>
						<Image src={mode == "dark" ? "/logo/Logo_black.svg" : "/logo/Logo.svg"} alt={"Formica Logo"} width={90} height={90} className={"h-4 md:h-7"} />
					</a>
				</div>
				<nav className={"max-w-fit items-center flex gap-x-6"}>
					{MobileLanguageMenuWrapper({
						items: [
/*							{
								title: t("header.languages.tr"),
								id: "tr",
								image: "/mega-menu-icon/language/tr.png",
								activeImage: "/mega-menu-icon/language/tr.png",
								disableArrowIcon: true
							},*/
							{
								title: t("header.languages.en"),
								id: "en",
								image: "/mega-menu-icon/language/en.png",
								activeImage: "/mega-menu-icon/language/en.png",
								disableArrowIcon: true
							}/*,
							{
								title: t("header.languages.es"),
								id: "es",
								image: "/mega-menu-icon/language/es.png",
								activeImage: "/mega-menu-icon/language/es.png",
								disableArrowIcon: true
							},
							{
								title: t("header.languages.sa"),
								id: "sa",
								image: "/mega-menu-icon/language/sa.png",
								activeImage: "/mega-menu-icon/language/sa.png",
								disableArrowIcon: true
							}*/
						],
						containerClassName: "grid-cols-none",
						children: HeaderButton({
							icon: "language_button",
							className: "flex cursor-pointer fill-white",
							iconProps: {
								width: 16,
								height: 16
							},
							mode
						})
					})}
					{MobileMegaMenuWrapper({
						items: [
							{
								title: t("header.solutions.title"),
								image: "/mega-menu-icon/mobile/Solution_Default.svg",
								activeImage: "/mega-menu-icon/mobile/Solution_Active.svg",
								items: [
									{
										title: t("header.solutions.items.transactionMonitoring"),
										href: "/solutions/transaction-monitoring",
										image: "transaction-monitoring"
									},
									{
										title: t("header.solutions.items.accountProtection"),
										href: "/solutions/account-protection",
										image: "account-protection"
									},
									{
										title: t("header.solutions.items.paymentOptimization"),
										href: "/solutions/payment-optimization",
										image: "payment-optimization"
									},
									{
										title: t("header.solutions.items.scamPrevention"),
										href: "/solutions/scam-prevention",
										image: "scam-prevention"
									},
									{
										title: t("header.solutions.items.accountOpening"),
										href: "/solutions/account-opening",
										image: "account-opening"
									},
									{
										title: t("header.solutions.items.amlKyc"),
										href: "/solutions/aml-kyc",
										image: "aml-and-kyc"
									}
								]
							},
							{
								title: t("header.industries.title"),
								image: "/mega-menu-icon/mobile/Industries_Default.svg",
								activeImage: "/mega-menu-icon/mobile/Industries_Active.svg",
								items: [
									{
										title: t("header.industries.items.banking"),
										href: "/industries/banking",
										image: "banking"
									},
									{
										title: t("header.industries.items.travelAndTicketing"),
										href: "/industries/travel-and-ticketing",
										image: "travel-and-ticketing"
									},
									{
										title: t("header.industries.items.paymentFacilitator"),
										href: "/industries/payment-facilitator",
										image: "payment-faciliator"
									},
									{
										title: t("header.industries.items.bnpl"),
										href: "/industries/bnpl",
										image: "bnpl"
									},
									{
										title: t("header.industries.items.eCommerce"),
										href: "/industries/e-commerce",
										image: "e-commerce"
									}
								]
							},
							{
								title: t("header.resources.title"),
								image: "/mega-menu-icon/mobile/Resources_Default.svg",
								activeImage: "/mega-menu-icon/mobile/Resources_Active.svg",
								items: [
									{
										title: t("header.resources.items.blogs"),
										href: "/blog",
										image: "blogs_mobile_icon"
									},
									{
										title: t("header.resources.items.eBooks"),
										href: "/e-books",
										image: "ebooks_mobile_icon"
									}
								]
							},
							{
								title: t("header.company.title"),
								image: "/mega-menu-icon/mobile/Company_Default.svg",
								activeImage: "/mega-menu-icon/mobile/Company_Active.svg",
								items: [
									{
										title: t("header.company.items.aboutUs"),
										href: "/company/about-us",
										image: "about-us"
									}
								]
							}
						],
						children: HeaderButton({
							icon: "down_arrow",
							className: "flex cursor-pointer fill-white",
							mode
						})
					})}
				</nav>
			</nav>
		</div>
	);
}

export interface GenericHeaderProps {
	containerClassName?: string;
	mode?: "light" | "dark";
}
