"use client"
import Image from "next/image";
import { HeaderButton } from "@/components/header/HeaderButton";
import GenericWhiteButton from "@/components/generic/GenericWhiteButton";
import MegaMenuWrapper from "@/components/mega-menu/MegaMenuWrapper";
import GenericBlueButton from "@/components/generic/GenericBlueButton";
import LanguageMenuWrapper from "@/components/mega-menu/language/LanguageMenuWrapper";
import {useTranslations} from "next-intl";

export default function GenericHeader({ containerClassName, mode }: GenericHeaderProps) {
	const t = useTranslations('');

	return (
		<div className={`w-full fixed bg-navbar_bg items-center flex justify-center h-20 z-10 ${containerClassName}`}>
			<nav className={"xl:max-w-[1080px] justify-between flex xl:gap-x-20"}>
				<a href={"/"} className={"w-[140px] flex items-center"}>
					<Image src={mode == "dark" ? "/logo/Logo_black.svg" : "/logo/Logo.svg"} alt={"Formica Logo"} width={140} height={34} />
				</a>
				<nav className={"max-w-fit items-center flex gap-x-6"}>
					{MegaMenuWrapper({
						title: t("header.solutions.title"),
						items: [
							{
								title: t("header.solutions.items.transactionMonitoring"),
								href: "/solutions/transaction-monitoring",
								image: "/mega-menu-icon/Transaction_Monitoring_Icon.svg"
							},
							{
								title: t("header.solutions.items.accountProtection"),
								href: "/solutions/account-protection",
								image: "/mega-menu-icon/Account_Protection_Icon.svg"
							},
							{
								title: t("header.solutions.items.paymentOptimization"),
								href: "/solutions/payment-optimization",
								image: "/mega-menu-icon/Payment_Optimization_Icon.svg"
							},
							{
								title: t("header.solutions.items.scamPrevention"),
								href: "/solutions/scam-prevention",
								image: "/mega-menu-icon/Scam_Prevention_Icon.svg"
							},
							{
								title: t("header.solutions.items.accountOpening"),
								href: "/solutions/account-opening",
								image: "/mega-menu-icon/Account_Opening_Icon.svg"
							},
							{
								title: t("header.solutions.items.amlKyc"),
								href: "/solutions/aml-kyc",
								image: "/mega-menu-icon/AML&KYC_Icon.svg"
							}
						],
						children: HeaderButton({
							title: t("header.solutions.title"),
							icon: "down_arrow",
							className: "flex h-20 items-center cursor-pointer fill-white",
							mode
						})
					})}
					{MegaMenuWrapper({
						title: t("header.industries.title"),
						items: [
							{
								title: t("header.industries.items.banking"),
								href: "/industries/banking",
								image: "/mega-menu-icon/Banking_Icon.svg"
							},
							{
								title: t("header.industries.items.travelAndTicketing"),
								href: "/industries/travel-and-ticketing",
								image: "/mega-menu-icon/Travel&Ticketing_Icon.svg"
							},
							{
								title: t("header.industries.items.paymentFacilitator"),
								href: "/industries/payment-facilitator",
								image: "/mega-menu-icon/Payment_Facilitator_Icon.svg"
							},
							{
								title: t("header.industries.items.bnpl"),
								href: "/industries/bnpl",
								image: "/mega-menu-icon/BNPL_Icon.svg"
							},
							{
								title: t("header.industries.items.eCommerce"),
								href: "/industries/e-commerce",
								image: "/mega-menu-icon/E-Commerce_Icon.svg"
							}
						],
						children: HeaderButton({
							title: t("header.industries.title"),
							icon: "down_arrow",
							className: "flex h-20 items-center cursor-pointer fill-white",
							mode
						})
					})}
					{MegaMenuWrapper({
						title: t("header.resources.title"),
						items: [
							{
								title: t("header.resources.items.blogs"),
								href: "/blog",
								image: "/mega-menu-icon/Blogs_Icon.svg"
							},
							{
								title: t("header.resources.items.eBooks"),
								href: "/e-books",
								image: "/mega-menu-icon/E-books_Icon.svg"
							}
						],
						children: HeaderButton({
							title: t("header.resources.title"),
							icon: "down_arrow",
							className: "flex h-20 items-center cursor-pointer fill-white",
							mode
						})
					})}
					{MegaMenuWrapper({
						title: t("header.company.title"),
						items: [
							{
								title: t("header.company.items.aboutUs"),
								href: "/company/about-us",
								image: "/mega-menu-icon/Banking_Icon.svg"
							}
						],
						containerClassName: "grid-cols-none",
						children: HeaderButton({
							title: t("header.company.title"),
							icon: "down_arrow",
							className: "flex h-20 items-center cursor-pointer fill-white",
							mode
						})
					})}
				</nav>
				<nav className={"items-center flex gap-x-6"}>
					{LanguageMenuWrapper({
						items: [
/*							{
								title: t("header.languages.tr"),
								id: "tr",
								image: "/mega-menu-icon/language/tr.png",
								disableArrowIcon: true
							},*/
							{
								title: t("header.languages.en"),
								id: "en",
								image: "/mega-menu-icon/language/en.png",
								disableArrowIcon: true
							}/*,
							{
								title: t("header.languages.es"),
								id: "es",
								image: "/mega-menu-icon/language/es.png",
								disableArrowIcon: true
							},
							{
								title: t("header.languages.sa"),
								id: "sa",
								image: "/mega-menu-icon/language/sa.png",
								disableArrowIcon: true
							}*/
						],
						containerClassName: "grid-cols-none",
						children: HeaderButton({
							icon: "language_button",
							className: "flex h-20 items-center cursor-pointer fill-white",
							mode
						})
					})}
					{mode == "dark" ?
						<GenericBlueButton title={t("tryNow.buttonTitle")} href={"#contact-us"} />
						: <GenericWhiteButton title={t("tryNow.buttonTitle")} href={"#contact-us"} />
					}
				</nav>
			</nav>
		</div>
	);
}

export interface GenericHeaderProps {
	containerClassName?: string,
	mode?: "light" | "dark"
}
